﻿declare global {
    interface String {
        isNullOrWhitespace(): boolean;
        format(): string;
        substringUntil(char: string): string;
        substringUntilLast(char: string): string;
    }
}

String.prototype.isNullOrWhitespace = function () {
    return !this || !(this.replace(/\s*/, ''));
};

String.prototype.format = function () {

    var args = arguments;
    var getSufficientArg = function (matchString: string, index: number) {

        return typeof args[index] != 'undefined'
            ? args[index]
            : matchString;
    };

    return this.replace(/{(\d+)}/g, getSufficientArg);
};

String.prototype.substringUntil = function (char) {

    return this.substr(0, this.indexOf(char));
};

String.prototype.substringUntilLast = function (char) {

    return this.substr(0, this.lastIndexOf(char));
};

export { }